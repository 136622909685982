import React from 'react';
import { merge } from 'lodash';

import Default from '../../../common/Themes/Default/Theme';
import { UX2, constants } from '@wsb/guac-widget-core';
import { categories } from '../../../common/constants';
import { spacingVertical } from '../../../common/utils';
import { getDial } from '../../../common/Themes/Default/Dials/Colors/utils';
import { levelFilter } from '../../../common/utils/text';
import { mergeTypographyOverrides } from '../../../common/utils/typography';
import * as modernIcons from '../../../common/IconPacks/modernThinRound';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Balance } from '../../../common/loaders';
import { ABOUT1_IMAGE_WIDTH, sectionHrTypes } from '../../../common/constants';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../../common/constants/headerTreatments';
import { getIndicatorStyle, getIndicatorActiveStyle, getAbsoluteIndicatorStyle } from '../../../common/utils/linkIndicator';
import * as utils from '../../../common/Themes/Default/Dials/Colors/utils';
import themeConfig from '../themeConfig';

const { NONE } = sectionHrTypes;
const { renderModes, buttons } = constants;
const { PRIMARY, NEUTRAL, ACCENT } = categories;

const FULL_BLEED_WIDGETS = [
  'about5',
  'content5',
  'mlsSearch1',
  'ordering1',
  'subscribe3',
  'introduction5'
];

const kaiLightGrey = '#abb2b2';
const kaiDarkGrey = '#737373';
const navItemPadding = 'xlarge';

const hasProp = (props, prop, value) => props[prop] && props[prop] === value;
const hasPropRegex = (props, prop, regex) => props[prop] && regex.test(props[prop]);
const hasDataAid = (props, value) => hasProp(props, 'data-aid', value);

const whiteBackgroundIconColor = {
  color: 'kaiDarkGrey',
  [':hover']: {
    color: 'kaiLightGrey'
  }
};

const inputBorderStyle = category => ({
  borderRadius: 0,
  borderTopWidth: 0,
  borderRightWidth: 0,
  borderLeftWidth: 0,
  borderBottomWidth: '0.04rem',
  borderBottomStyle: 'solid',
  borderColor: category === NEUTRAL ? 'kaiDarkGrey' : 'highContrast'
});

const navDropdownBorderColor = isHomepage => (
  isHomepage
    ? {
      [PRIMARY]: 'primaryContrastShade',
      [ACCENT]: '#242424',
      [NEUTRAL]: '#EEEEEE'
    }
    : {
      [PRIMARY]: 'primaryContrastShade',
      [ACCENT]: '#5B5B5B',
      [NEUTRAL]: '#C8C8C8'
    }
);

const primaryContrast = theme => {
  const [primary] = getDial(theme, ['primary']);
  return primary.setAlpha(100).contrast(5);
};

const imageTreatments = {
  [FILL]: 'accent-overlay',
  [FIT]: 'accent-overlay',
  [INSET]: 'category-solid',
  [BLUR]: 'accent-overlay',
  [LEGACY_BLUR]: 'accent-overlay'
};

const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highContrast',
    fontSize: 'xxlarge',
    fontWeight: 'normal',
    letterSpacing: '2px',
    textTransform: 'none'
  }
};

class Theme28 extends Default {

  static config = themeConfig;

  static get displayName() {
    return 'Theme28';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);

    return widgetType === 'HEADER' ? {
      ...defaultProps,
      showOverlayOpacityControls: true,
      hasLogoAlign: true,
      hasNavBackgroundToggle: true,
      useSlideshow: true,
      headerTreatmentsConfig: {
        ...defaultProps.headerTreatmentsConfig,
        defaultHeaderTreatment: FILL,
        imageTreatments,
        heroContentItems: ['tagline', 'tagline2', 'cta'],
        nonHeroContentItems: ['phone']
      }
    } : defaultProps;
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(widgetType === 'HEADER' && { alignmentOption: 'left', logoAlign: 'center' })
    };
  }

  constructor() {
    super();
    /* eslint-disable id-length */
    this.mappedValues = {
      ...this.mappedValues,
      backgroundColorKaiDarkGrey: kaiDarkGrey,
      backgroundColorBackgroundContrast() {
        const [background] = getDial(this, ['background']);
        return background.setAlpha(100).contrast(5);
      },
      backgroundColorPrimaryContrast() {
        return primaryContrast(this);
      },
      borderColorPrimaryContrastShade() {
        const primaryShade = primaryContrast(this);
        const shade = primaryShade.isLight() ? 'darken' : 'lighten';
        return primaryShade.setAlpha(100)[shade](5);
      },
      backgroundColorNavSolid() {
        const background = utils.getDial(this, 'background');
        return background.setAlpha(25);
      },
      borderColorKaiDarkGrey: kaiDarkGrey,
      colorKaiLightGrey: kaiLightGrey,
      colorKaiDarkGrey: kaiDarkGrey,
      fillKaiDarkGrey: kaiDarkGrey,
      fontSizeCloseIconMobile: '25px',
      spacingKaiSectionVertical: '100px',
      webkitTextFillColorKaiDarkGrey: kaiDarkGrey,
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xxxlarge',
            fontWeight: 'normal',
            letterSpacing: '4px',
            textTransform: 'none',
            ['@xs-only']: {
              letterSpacing: '2px'
            }
          }
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'none'
          }
        },
        HeadingDelta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'none'
          }
        },
        HeadingEpsilon: {
          ...typographyShared1
        },
        BodyAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        BodyBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'mlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        DetailsAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        DetailsBeta: {
          style: {
            font: 'alternate',
            color: 'neutral',
            fontSize: 'xsmall',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: props => {
          const { size = 'default' } = props;
          const sizes = {
            small: {
              fontSize: 'small'
            },
            default: {
              fontSize: 'medium'
            },
            large: {
              fontSize: 'medium'
            }
          };
          return {
            style: {
              font: 'primary',
              fontWeight: 'normal',
              letterSpacing: '2px',
              textTransform: 'none',
              ...sizes[size]
            }
          };
        },
        NavAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: '2px',
            textTransform: 'none',
            [':hover']: {
              opacity: 0.5,
              color: 'highContrast'
            },
            [':active']: {
              color: 'highlight'
            }
          }
        },
        SubNavAlpha: props => mergeTypographyOverrides.call(this, 'NavAlpha', props),
        NavBeta: ({ active = false }) => {
          return {
            style: {
              font: 'primary',
              fontSize: 'mlarge',
              fontWeight: 'normal',
              letterSpacing: '2px',
              textTransform: 'none',
              color: active ? 'black' : 'kaiDarkGrey',
              [':hover']: {
                opacity: 0.5,
                color: 'kaiLightGrey'
              }
            }
          };
        },
        SubNavBeta: props => mergeTypographyOverrides.call(this, 'NavBeta', props),
        InputAlpha: props => merge(mergeTypographyOverrides.call(this, 'BodyAlpha', props), {
          style: {
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        }),
        InputBeta: props => merge(mergeTypographyOverrides.call(this, 'InputAlpha', props), {
          style: {
            color: 'kaiDarkGrey'
          }
        })
      }
    };
  }

  /* Base Components */
  Button(props) {
    const { size = 'default' } = props;
    const { fill = this.getButtonDefaults().fill } = props;
    const ghostOverride = fill !== buttons.fills.OPEN ? {
      style: {
        transitionProperty: 'background-color',
        transitionDuration: 'medium',
        transitionTimingFunction: 'ease-in-out'
      }
    } : {};
    const sizes = {
      small: {
        minHeight: 40
      }
    };

    return super.Button(this.merge({
      style: {
        ...sizes[size],
        ['@xs-only']: {
          width: 'auto'
        }
      },
      customBorderWidth: 'small'
    }, ghostOverride, props));
  }

  Divider(props) {
    return super.Divider(this.merge({
      style: {
        display: 'none'
      }
    }, props));
  }

  Heading(props) {
    const { widgetPreset } = this.base;
    const { level, tag } = props;
    const typography = {
      2: 'HeadingEpsilon',
      5: 'DetailsAlpha'
    }[levelFilter({ tag, level })];

    return super.Heading(this.merge({
      style: {
        ...(widgetPreset === 'content4' || widgetPreset === 'introduction4') && {
          marginBottom: '40px !important'
        }
      },
      typography
    }, props));
  }

  Icon(props) {
    const { widgetType } = this.base;
    const isMembershipIcon = hasDataAid(props, 'MEMBERSHIP_ICON_RENDERED');

    return super.Icon(this.merge({
      iconPack: { ...modernIcons, ...socialIconPack },
      style: {
        ...widgetType === 'HEADER' && {
          color: 'highContrast',
          [':hover']: {
            color: 'highContrast',
            opacity: 0.5
          }

        },
        ...isMembershipIcon && {
          ...whiteBackgroundIconColor
        }
      }
    }, props));
  }

  SlideshowArrowsIcon(props) {
    return this.Icon(this.merge({
      style: {
        ':hover': {
          opacity: 1
        }
      }
    }, props));
  }

  MembershipHeading(props) {
    return super.MembershipHeading(this.merge({
      style: {
        textAlign: 'center'
      }
    }, props));
  }

  MembershipHR(props) {
    return this.HR(this.merge({
      style: {
        '@xs': {
          borderColor: 'section',
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0
        }
      }
    }, props));
  }

  MembershipText(props) {
    return this.Text(this.merge({
      style: {
        textAlign: 'center'
      }
    }, props));
  }

  Loader(props) {
    return Balance.apply(this, [props]);
  }

  /* Nav */
  Nav(props) {
    return super.Nav(this.merge({
      style: {
        maxWidth: 'none'
      }
    }, props));
  }

  NavLink(props) {
    return super.NavLink(this.merge({
      style: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        ...getIndicatorStyle()
      }
    }, props));
  }

  NavLinkActive(props) {
    return super.NavLink(this.merge({
      style: getIndicatorActiveStyle({
        transform: 'translateY(-50%)',
        height: 2
      })
    }, props));
  }

  NavMoreMenuLinkActive(props) {
    return super.NavLinkActive(props);
  }

  NavDropdown(props) {
    const { category, isHomepage } = this.base;
    return super.Dropdown(this.merge({
      style: {
        '@md': {
          padding: 'large',
          borderWidth: 'xsmall',
          borderColor: navDropdownBorderColor(isHomepage)[category],
          borderStyle: 'solid',
          borderRadius: 'none',
          boxShadow: 'none',
          lineHeight: 1.65
        }
      }
    }, props));
  }

  UtilitiesMenu(props) {
    return super.UtilitiesMenu(this.merge({
      style: {
        'justifyContent': 'flex-end',
        ' > span:first-child': {
          '@md': {
            marginLeft: '0 !important'
          }
        }
      }
    }, props));
  }

  /* Grid */
  Grid(props) {
    const { widgetPreset } = this.base;
    return super.Grid(this.merge({
      style: {
        ...widgetPreset === 'gallery4' && {
          margin: '-xxsmall'
        }
      }
    }, props));
  }

  GridCell(props) {
    const { widgetPreset, widgetType } = this.base;
    const isGalleryCell = hasPropRegex(props, 'data-aid', /GALLERY_IMAGE[\d]+_CELL_RENDERED/g);

    return super.GridCell(this.merge({
      style: {
        ...widgetType === 'HEADER' && {
          '.nav-item.visible': {
            paddingRight: navItemPadding,
            paddingLeft: 0
          },
          '.last-visible-nav-item': {
            paddingRight: '0 !important'
          }
        },
        ...widgetType === 'SHOP_FEATURED_CATEGORY' && {
          '@xs': {
            paddingHorizontal: 0
          }
        },
        ...widgetPreset === 'about3' && {
          '@md': {
            ':first-of-type': {
              paddingBottom: 'xxxlarge'
            }
          }
        },
        ...widgetPreset === 'contact4' && {
          '@md': {
            'paddingBottom': '80px !important',
            ':last-of-type': {
              paddingBottom: '0 !important'
            }
          }
        },
        ...widgetPreset === 'gallery4' && isGalleryCell && {
          padding: 'xsmall'
        }
      }
    }, props));
  }


  /* Navigation Drawer */
  NavigationDrawer(props) {
    const { renderMode } = this.base;
    const isSearchContainer = hasPropRegex(props, 'id', /Search[\d]+-container/g);

    return super.NavigationDrawer(this.merge({
      category: 'neutral',
      section: 'default',
      style: {
        backgroundColor: 'section',
        paddingTop: 'xxxlarge',
        ...isSearchContainer && {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        },
        ...renderMode === renderModes.PREVIEW && {
          '@xs': {
            top: 0
          }
        }
      }
    }, props));
  }

  NavigationDrawerInputSearch(props) {
    return super.NavigationDrawerInputSearch(this.merge({
      style: {
        backgroundColor: 'searchMobileLight'
      },
      typography: 'InputBeta'
    }, props));
  }

  NavigationDrawerListItem(props) {
    return super.NavigationDrawerListItem(this.merge({
      style: {
        borderBottomWidth: 0
      }
    }, props));
  }

  NavigationDrawerLink(props) {
    return super.NavigationDrawerLink(this.merge({
      style: {
        justifyContent: 'center',
        paddingVertical: '25px'
      }
    }, props));
  }

  NavigationDrawerLinkActive({ children, ...props }) {
    return super.NavigationDrawerLinkActive(this.merge({
      style: {
        justifyContent: 'center'
      },
      children: (
        <UX2.Element.Block
          style={{
            position: 'relative',
            ...getAbsoluteIndicatorStyle({
              backgroundColor: 'sectionLowContrast',
              height: 2
            })
          }}
        >
          { children }
        </UX2.Element.Block>
      )
    }, props));
  }

  NavigationDrawerLinkDropdownActive(props) {
    return this.NavigationDrawerLinkActive(props);
  }

  NavigationDrawerSubLink(props) {
    return super.NavigationDrawerSubLink(this.merge({
      style: {
        paddingLeft: 0,
        paddingRight: 0,
        display: 'flex',
        justifyContent: 'center'
      }
    }, props));
  }

  CloseIcon(props) {
    const isSearchCloseIcon = hasDataAid(props, 'SEARCH_CLOSE_RENDERED');

    return super.CloseIcon(this.merge({
      style: {
        '@xs-only': {
          fontSize: 'closeIconMobile'
        },
        '@md': {
          ...isSearchCloseIcon && {
            zIndex: 5,
            ...whiteBackgroundIconColor
          }
        }
      }
    }, props));
  }

  NavigationDrawerCloseIcon(props) {
    const { renderMode } = this.base;
    const isSearchCloseIcon = hasDataAid(props, 'SEARCH_CLOSE_RENDERED');

    return super.CloseIcon(this.merge({
      style: {
        '@xs': {
          ...whiteBackgroundIconColor,
          top: 15,
          right: 10,
          ...isSearchCloseIcon && {
            left: '50%',
            top: '85%',
            transform: 'translateX(-50%)',
            ...whiteBackgroundIconColor,
            ...renderMode === renderModes.PREVIEW && {
              position: 'absolute',
              top: '80%',
              left: 'unset',
              right: 'unset',
              transform: 'unset',
              alignSelf: 'center',
              ['+ div']: {
                position: 'absolute',
                top: '50%',
                width: '100%'
              }
            }
          }
        }
      }
    }, props));
  }

  GoogleTranslate(props) {
    return super.GoogleTranslate(this.merge({
      '@xs': {
        width: 60
      }
    }, props));
  }

  GoogleTranslateIcon(props) {
    return super.Icon(this.merge({
      style: {
        ...whiteBackgroundIconColor
      }
    }, props));
  }

  /* Logo */
  LogoHeading(props) {
    return super.LogoHeading(this.merge({
      style: {
        overflowWrap: 'break-word',
        ['@md']: {
          margin: 0,
          maxWidth: 'none'
        }
      }
    }, props));
  }

  /* Hero */
  Hero(props) {
    return super.Hero(this.merge({
      style: {
        'textAlign': 'center',
        '@md': {
          maxWidth: '100%',
          ...spacingVertical('small')
        }
      }
    }, props));
  }

  HeroLeft(props) {
    return this.Hero(this.merge({
      style: {
        '@sm': {
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderLeftStyle: 'solid',
          borderLeftWidth: '1px',
          paddingLeft: 'large'
        },
        'border': 'none',
        'paddingLeft': 'none'
      }
    }, props));
  }

  HeroCenterHeading(props) {
    return this.HeroHeading(this.merge({
      style: {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderColor: 'rgba(255, 255, 255, 0.5)',
        paddingBottom: 'small'
      }
    }, props));
  }

  HeroRight(props) {
    return this.Hero(this.merge({
      style: {
        '@sm': {
          borderColor: 'rgba(255, 255, 255, 0.5)',
          borderRightStyle: 'solid',
          borderRightWidth: '1px',
          paddingRight: 'large'
        },
        'border': 'none',
        'paddingRight': 0
      }
    }, props));
  }

  Tagline(props) {
    return super.Tagline(this.merge({
      style: {
        position: 'relative',
        marginHorizontal: 'auto'
      }
    }, props));
  }

  HeroText(props) {
    return super.SubTagline(this.merge({
      style: {
        display: 'inline-block',
        lineHeight: '1.5',
        maxWidth: '100%',
        ['@xs']: {
          marginBottom: 'small'
        }
      },
      typography: 'HeadingDelta'
    }, props));
  }

  Phone(props) {
    return super.Phone(this.merge({
      style: {
        marginBottom: 'small'
      },
      typography: 'BodyAlpha'
    }, props));
  }


  /* Section */
  Section(props) {
    const { widgetType } = this.base;
    const { children, skipDivider } = props;

    if (widgetType === 'FOOTER' && !skipDivider) {
      return super.Section(this.merge({
        children: (
          <React.Fragment>
            <UX2.Element.Divider style={{ paddingVertical: 'medium' }} />
            { children }
          </React.Fragment>
        )
      }, props));
    }

    return super.Section(this.merge({
      style: {
        ...widgetType !== 'FOOTER' && {
          paddingVertical: 'kaiSectionVertical'
        },
        ...widgetType === 'SUBSCRIBE' && {
          paddingVertical: 'xxxlarge'
        }
      }
    }, props));
  }

  SectionBanner(props) {
    const { widgetPreset } = this.base;

    return super.SectionBanner(this.merge({
      style: {
        ...['gallery2'].includes(widgetPreset) && {
          padding: 0
        }
      }
    }, props));
  }

  SectionSplit(props) {
    const { widgetPreset } = this.base;

    return super.SectionSplit(this.merge({
      style: {
        '@md': {
          ...widgetPreset === 'about5' && {
            maxWidth: 'unset',
            paddingHorizontal: 'xxxlarge'
          }
        }
      }
    }, props));
  }

  SectionContainer(props) {
    const { widgetPreset, widgetType } = this.base;

    return super.Container(this.merge({
      style: {
        '@md': {
          paddingHorizontal: 'xxlarge',
          ...widgetType === 'HEADER' && {
            width: '100%',
            margin: '0 auto',
            paddingHorizontal: 'small'
          },
          ...widgetType === 'FOOTER' && {
            minWidth: '100%'
          },
          ...FULL_BLEED_WIDGETS.includes(widgetPreset) && {
            paddingHorizontal: 0
          },
          ...widgetPreset === 'contact4' && {
            paddingHorizontal: 0
          },
          ...widgetPreset === 'contact5' && {
            '> [data-ux=Group]': {
              display: 'flex',
              flexDirection: 'column-reverse'
            }
          }
        }
      }
    }, props));
  }

  SectionHeading({ alignmentOption, ...props }) {
    const { widgetPreset } = this.base;
    return super.SectionHeading(this.merge({
      style: {
        textAlign: alignmentOption || 'center',
        ['@md']: {
          ...widgetPreset === 'content7' ? {
            marginVertical: 'kaiSectionVertical'
          } : {
            marginBottom: 'kaiSectionVertical'
          }
        },
        ...widgetPreset === 'contact3' && {
          marginBottom: 'xsmall'
        }
      },
      sectionHeadingHR: NONE
    }, props));
  }

  Intro(props) {
    return super.Intro(this.merge({
      alignment: 'center',
      style: {
        ['@md']: {
          marginBottom: 'kaiSectionVertical'
        }
      }
    }, props));
  }

  /* Card Banner */
  CardBanner(props) {
    return super.CardBanner(this.merge({
      style: {
        ['@md']: {
          flexDirection: 'column !important'
        }
      }
    }, props));
  }

  CardBannerBlock(props) {
    return super.CardBannerBlock(this.merge({
      style: {
        'display': 'flex',
        'justifyContent': 'center !important',
        'width': '100%',
        '@md': {
          padding: '0 !important'
        }
      }
    }, props));
  }

  CardBannerHeading(props) {
    return super.CardBannerHeading(this.merge({
      style: {
        ['@md']: {
          textAlign: 'center',
          marginBottom: 'large'
        }
      }
    }, props));
  }

  /* Content */
  Content(props) {
    const { widgetPreset } = this.base;

    return super.Content(this.merge({
      style: {
        ...widgetPreset === 'about5' && {
          margin: 0
        }
      }
    }, props));
  }

  ContentBasic(props) {
    const { widgetPreset } = this.base;

    return super.ContentBasic(this.merge({
      style: {
        ...['about5', 'content1'].includes(widgetPreset) && {
          marginBottom: '80px !important'
        }
      }
    }, props));
  }

  ContentText(props) {
    return super.ContentText(this.merge({
      style: {
        ['> p']: {
          lineHeight: 1.8
        }
      }
    }, props));
  }

  ContentCardImageThumbnail(props) {
    const { widgetPreset } = this.base;

    const squareImages = {
      borderRadius: 'none',
      width: ABOUT1_IMAGE_WIDTH
    };

    return super.ContentCardImageThumbnail(this.merge({
      style: {
        ...widgetPreset === 'about1' && {
          ...squareImages
        }
      }
    }, props));
  }

  ContentCardHeading(props) {
    const { widgetPreset } = this.base;
    return super.ContentCardHeading(this.merge({
      style: {
        ...widgetPreset === 'about1' && {
          ['@md']: {
            marginTop: 'medium'
          }
        }
      }
    }, props));
  }


  /* Group */
  Group(props) {
    const { widgetType } = this.base;
    const isFeaturedCategory = widgetType === 'SHOP_FEATURED_CATEGORY'
      && hasPropRegex(props, 'data-route', /categories\/[\d]+/g);
    const desktopSize = 320;
    const mobileSize = '90vw';

    return super.Group(this.merge({
      style: {
        '@xs': {
          ...isFeaturedCategory && {
            width: mobileSize,
            height: mobileSize,
            margin: '0 auto'
          }
        },
        '@md': {
          ...isFeaturedCategory && {
            width: desktopSize,
            height: desktopSize
          }
        }
      }
    }, props));
  }


  /* Background */
  Background(props) {
    const { widgetType } = this.base;

    return super.Background(this.merge({
      style: {
        ...widgetType === 'HEADER' && {
          zIndex: 1
        }
      }
    }, props));
  }

  /* Table */
  Table(props) {
    return super.Table(this.merge({
      style: {
        borderRadius: 'medium'
      }
    }, props));
  }

  /* Input */
  getSection(inputSection) {
    if (inputSection === 'default') {
      return 'alt';
    } else if (inputSection === 'overlay') {
      return 'overlay';
    }
    return 'default';
  }

  Input(props) {
    const hoverFocus = { backgroundColor: 'section' };
    return super.Input(this.merge({
      section: this.getSection(props.section || this.base.section),
      style: {
        paddingVertical: 'xsmall',
        paddingHorizontal: 'xsmall',
        ...inputBorderStyle(this.base.category),
        [':hover']: {
          ...hoverFocus
        },
        [':focus']: {
          boxShadow: 'none',
          ...hoverFocus
        }
      }
    }, props));
  }

  InputFloatLabelLabel(props) {
    return super.InputFloatLabelLabel(this.merge({
      style: {
        left: 'xsmall'
      }
    }, props));
  }

  InputSearch(props) {
    return super.InputSearch(this.merge({
      style: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        borderRadius: 0,
        [':focus']: {
          backgroundColor: 'transparent'
        }
      },
      typography: 'InputBeta'
    }, props));
  }

  SearchPopout(props) {
    return super.SearchPopout(this.merge({
      style: {
        borderBottom: `1px solid ${kaiDarkGrey}`
      }
    }, props));
  }

  /* Welcome */
  FeaturedText(props) {
    const { widgetPreset } = this.base;

    return super.FeaturedText(this.merge({
      style: {
        ...(widgetPreset === 'content4' || widgetPreset === 'introduction4') && {
          marginBottom: '30px !important'
        }
      }
    }, props));
  }


  /* Promo Banner */
  PromoBanner(props) {
    return super.PromoBanner(this.merge({
      category: 'primary',
      style: {
        position: 'relative',
        zIndex: 2,
        backgroundColor: 'primaryContrast'
      }
    }, props));
  }

  PromoBannerContainer(props) {
    return super.PromoBannerContainer(this.merge({
      style: {
        paddingVertical: 'xxsmall',
        ['@md']: {
          paddingVertical: 'xxsmall'
        }
      }
    }, props));
  }

  HeaderMediaImage({ groupType , ...props }) {
    const mobileGutterWidth = [INSET, BLUR].indexOf(groupType) >= 0 && 40;
    return super.Image(this.merge({
      style: {
        marginTop: 0,
        paddingHorizontal: 'small',
        ['@md']: {
          paddingHorizontal: 0
        }
      }
    }, props, { mobileGutterWidth }));
  }

  InputFloatLabelInput(props) {
    return super.InputFloatLabelInput(this.merge({
      style: {
        paddingBottom: 'xsmall'
      }
    }, props));
  }

  InputSelect(props) {
    return super.InputSelect(this.merge({
      style: {
        borderWidth: 'xsmall',
        borderRadius: 'medium',
        borderStyle: 'solid'
      }
    }, props));
  }

  InputSelectElement(props) {
    return super.InputSelectElement(this.merge({
      style: {
        padding: 'small'
      }
    }, props));
  }

  DividerHR(props) {
    return super.HR(this.merge({
      style: {
        borderColor: '! #999'
      }
    }, props));
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(this.merge({
      style: {
        borderRadius: '0'
      }
    }, props));
  }

  HeadingMajor(props) {
    return super.HeadingMajor(this.merge({ typography: 'HeadingEpsilon' }, props));
  }

  FeaturedHeading(props) {
    return super.FeaturedHeading(this.merge({ typography: 'HeadingEpsilon' }, props));
  }

  ContentBigHeading(props) {
    return super.ContentBigHeading(this.merge({ typography: 'HeadingDelta' }, props));
  }
}

export default Theme28;
